import "./App.css";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { Provider } from "react-redux";
import store from "./store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
	return (
		// <ItemList />
		// <ClientList />
		<Provider store={store}>
			<RouterProvider router={router} />
			<ToastContainer />
		</Provider>
		// <LoginPage />
		// <h1 className="text-3xl font-bold underline">
		//   Hello world!
		// </h1>
	);
}

export default App;
