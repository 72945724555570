import Sidebar from "../components/Sidebar";
import {
	addClient,
	fetchAdmins,
	deleteAdmin,
} from "../store/actions/actionCreator";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import EditAdminModal from "../components/EditAdminModal";
import AddAdminModal from "../components/AddAdminModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

export default function AdminList() {
	const [addForm, setAddForm] = useState({});
	const isShrunkDesktop = useMediaQuery({ query: "(max-width: 1263px" });
	const isFullDesktop = useMediaQuery({ query: "(min-width: 1264px" });
	const isMobile = useMediaQuery({ query: "(max-width: 770px" });
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [adminInfo, setAdminInfo] = useState({});
	const handleClose = () => {
		setShowAddModal(false);
		setShowEditModal(false);
	};
	const handleSave = () => {
		console.log("success");
	};

	function handleDelete(id) {
		dispatch(deleteAdmin(id))
			.then(() => dispatch(fetchAdmins()))
			.catch((error) => console.log(error));
	}

	const changeHandler = (e) => {
		const { value, name } = e.target;
		const obj = { ...addForm };
		obj[name] = value;
		setAddForm(obj);
		console.log(addForm);
	};

	function handleAdd() {
		dispatch(addClient(addForm))
			.then(() => setShowAddModal(false))
			.then(() => dispatch(fetchAdmins()))
			.catch((error) => console.log(error));
	}

	const renderBackdrop = (props) => <div className="backdrop" />;
	let marginLeftComponent;
	if (isShrunkDesktop) {
		marginLeftComponent = "100px";
	} else {
		marginLeftComponent = "250px";
	}

	const dispatch = useDispatch();

	const admins = useSelector((state) => {
		return state.admins.admins;
	});

	useEffect(() => {
		dispatch(fetchAdmins());
	}, []);

	return (
		<div className="flex w-screen h-screen">
			{showAddModal && (
				<AddAdminModal
					showAddModal={showAddModal}
					handleClose={handleClose}
				/>
			)}
			{showEditModal && (
				<EditAdminModal
					showEditModal={showEditModal}
					handleClose={handleClose}
					adminInfo={adminInfo}
				/>
			)}
			<button
				className="absolute bg-themeBlue bottom-10 right-10 px-8 py-4 text-white shadow-lg"
				onClick={() => setShowAddModal(true)}
			>
				Tambah admin baru
			</button>

			<Sidebar />
			<div
				className="grow px-10 py-10 flex flex-col items-start"
				style={{ marginLeft: marginLeftComponent }}
			>
				<div className="w-full border-b-2 border-themeGreen">
					<h1 className="text-2xl font-bold tracking-wide text-left">Admins</h1>
				</div>
				<div className="w-full rounded-lg mt-12 px-4 py-2">
					<table className="w-full">
						<thead className="">
							<tr className="text-left text-sm border-b bg-themeGreen text-white py-2">
								<th className="px-2 py-1">Admin ID</th>
								<th className="px-2 py-1">Username</th>
								<th className="px-2 py-1"></th>
							</tr>
						</thead>
						{admins && (
							<tbody>
								{admins.map((admin) => {
									if (admin.role !== "director") {
										return (
											<tr
												className="text-left text-sm border-b"
												key={admin.id}
											>
												<td className="px-2 py-3 font-bold">{admin._id}</td>
												<td className="px-2 py-3">{admin.username}</td>
												<td className="px-2 py-3 flex gap-4 justify-end pr-20">
													<FontAwesomeIcon
														icon={faPenToSquare}
														className="px-2 py-1 rounded-lg cursor-pointer text-themeBlack"
														onClick={() => {
															setShowEditModal(true);
															setAdminInfo(admin);
														}}
													/>
													<FontAwesomeIcon
														icon={faTrash}
														className="px-2 py-1 rounded-lg cursor-pointer text-themeBlack"
														onClick={() => handleDelete(admin.id)}
													/>
												</td>
											</tr>
										);
									}
								})}
							</tbody>
						)}
					</table>
				</div>
			</div>
		</div>
	);
}
