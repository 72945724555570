import * as types from "./actionType";
import FileDownload from "js-file-download";
// const baseUrl = "http://localhost:4000/";
const baseUrl = "https://api.samkalibrasi.com:4001/"
const { dateToString } = require("../helpers/helpers.js");

const fetchItemsSuccess = (payload) => {
	return {
		type: types.FETCH_ALL_ITEMS,
		payload,
	};
};

export const fetchItems = (input) => {
	// console.log(input);
	let url;
	if (input) {
		const { searchSN, UserId, TypeId, StatusId, page, searchCert } = input;
		url =
			baseUrl +
			`items?TypeId=${TypeId}&serialNumber=${searchSN}&StatusId=${StatusId}&UserId=${UserId}&page=${page}&cert=${searchCert}`;
	} else {
		url = baseUrl + "items?page=0";
	}
	console.log(url);
	return async (dispatch) => {
		return fetch(url, {
			headers: {
				access_token: localStorage.access_token,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				dispatch(fetchItemsSuccess(data));
			})
			.catch((error) => console.log(error));
	};
};

const fetchClientsSuccess = (payload) => {
	return {
		type: types.FETCH_ALL_CLIENTS,
		payload,
	};
};

export const fetchClients = (UserId) => {
	let url;
	url = baseUrl + "clients";
	return (dispatch) => {
		return fetch(url, {
			headers: {
				access_token: localStorage.access_token,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				// console.log(data, "data");
				dispatch(fetchClientsSuccess(data));
			})
			.catch((error) => console.log(error));
	};
};

const fetchAdminsSuccess = (payload) => {
	return {
		type: types.FETCH_ALL_ADMINS,
		payload,
	};
};

export const fetchAdmins = () => {
	let url;

	url = baseUrl + "admins";
	console.log(url);
	return (dispatch) => {
		return fetch(url, {
			headers: {
				access_token: localStorage.access_token,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				// console.log(data, "data");
				dispatch(fetchAdminsSuccess(data));
			})
			.catch((error) => console.log(error));
	};
};

const fetchBrandsSuccess = (payload) => {
	return {
		type: types.FETCH_ALL_BRANDS,
		payload,
	};
};

const fetchTypesSuccess = (payload) => {
	return {
		type: types.FETCH_ALL_TYPES,
		payload,
	};
};

export const fetchTypes = () => {
	let url;
	url = baseUrl + "types";
	return (dispatch) => {
		return fetch(url, {
			headers: {
				access_token: localStorage.access_token,
			},
		})
			.then((response) => {
				// console.log(response)
				return response.json();
			})
			.then((data) => {
				let result = data.map((el) => {
					return {
						value: el.id,
						label: el.name,
					};
				});
				dispatch(fetchTypesSuccess(result));
			})
			.catch((error) => console.log(error));
	};
};

const fetchStatusesSuccess = (payload) => {
	return {
		type: types.FETCH_ALL_STATUSES,
		payload,
	};
};

export const fetchStatuses = () => {
	let url;
	url = baseUrl + "statuses";
	return async (dispatch) => {
		return fetch(url, {
			headers: {
				access_token: localStorage.access_token,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				dispatch(fetchStatusesSuccess(data));
			})
			.catch((error) => console.log(error));
	};
};

export const fetchBrands = () => {
	let url;
	url = baseUrl + "brands";
	return (dispatch) => {
		return fetch(url, {
			headers: {
				access_token: localStorage.access_token,
			},
		})
			.then((response) => {
				// console.log(response)
				return response.json();
			})
			.then((data) => {
				dispatch(fetchBrandsSuccess(data));
			})
			.catch((error) => console.log(error));
	};
};

export const addItem = (input) => {
	console.log(input);
	let url = baseUrl + "items";
	console.log(url);
	return (dispatch) => {
		return fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				access_token: localStorage.access_token,
			},
			body: JSON.stringify(input),
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((text) => {
					throw new Error(text.message);
				});
			} else {
				return response.json().then((data) => {
					return data;
				});
			}
		});
	};
};

export const editItem = (input, id) => {
	console.log(input);
	let url = baseUrl + "items/" + id;
	return () => {
		return fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				access_token: localStorage.access_token,
			},
			body: JSON.stringify(input),
		})
			.then((response) => {
				if (!response.ok) {
					return response.json().then((text) => {
						throw new Error(text.message);
					});
				} else {
					return response.json().then((data) => {
						return data;
					});
				}
			})
			.catch((err) => console.log(err));
	};
};

export const updateItemStatus = (input, id) => {
	let url = baseUrl + "items/" + id;
	return async () => {
		return fetch(url, {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
				access_token: localStorage.access_token,
			},
			body: JSON.stringify(input),
		})
			.then(async (response) => {
				if (!response.ok) {
					return response.json().then((text) => {
						throw new Error(text.message);
					});
				} else {
					return response.json().then((data) => {
						return data;
					});
				}
			})
			.catch((err) => console.log(err));
	};
};

export const addClient = (input) => {
	let url = baseUrl + "clients";
	return (dispatch) => {
		return fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				access_token: localStorage.access_token,
			},
			body: JSON.stringify(input),
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((text) => {
					throw new Error(text.message);
				});
			} else {
				return response.json().then((data) => {
					return data;
				});
			}
		});
	};
};

export const addAdmin = (input) => {
	let url = baseUrl + "admins";
	return (dispatch) => {
		return fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				access_token: localStorage.access_token,
			},
			body: JSON.stringify(input),
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((text) => {
					throw new Error(text.message);
				});
			} else {
				return response.json().then((data) => {
					return data;
				});
			}
		});
	};
};

export const deleteItem = (id) => {
	let url = baseUrl + "items/" + id;
	return (dispatch) => {
		return fetch(url, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				access_token: localStorage.access_token,
			},
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((text) => {
					throw new Error(text.message);
				});
			} else {
				return response.json().then((data) => {
					return data;
				});
			}
		});
	};
};

export const deleteClient = (id) => {
	let url = baseUrl + "clients/" + id;
	return (dispatch) => {
		return fetch(url, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				access_token: localStorage.access_token,
			},
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((text) => {
					throw new Error(text.message);
				});
			} else {
				return response.json().then((data) => {
					return data;
				});
			}
		});
	};
};

export const deleteAdmin = (id) => {
	let url = baseUrl + "admins/" + id;
	return (dispatch) => {
		return fetch(url, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				access_token: localStorage.access_token,
			},
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((text) => {
					throw new Error(text.message);
				});
			} else {
				return response.json().then((data) => {
					return data;
				});
			}
		});
	};
};

export const downloadCertificate = (id, saveas) => {
	let url = baseUrl + `items/${id}/certificate`;
	console.log(url);
	return (dispatch) => {
		return fetch(url, {
			headers: {
				access_token: localStorage.access_token,
			},
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((text) => {
					throw new Error(text.message);
				});
			} else {
				return response.blob().then((data) => {
					const fileName = response.headers.get("FileName");
					let url = window.URL.createObjectURL(data),
						anchor = document.createElement("a");
					anchor.href = url;
					anchor.download = saveas;
					FileDownload(data, fileName);
					window.URL.revokeObjectURL(url);
					document.removeChild(anchor);
				});
			}
		});
	};
};

export const downloadAllCertificate = (paths, saveas) => {
	let url = baseUrl + `items/downloadAll`;
	return (dispatch) => {
		return fetch(url, {
			method: "POST",
			headers: {
				access_token: localStorage.access_token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ paths: paths }),
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((text) => {
					throw new Error(text.message);
				});
			} else {
				return response.blob().then((data) => {
					const date = dateToString(new Date());
					const zipFileName = `certificates-${date}.zip`;
					let url = window.URL.createObjectURL(data),
						anchor = document.createElement("a");
					anchor.href = url;
					anchor.download = saveas;
					FileDownload(data, zipFileName);
					window.URL.revokeObjectURL(url);
					document.removeChild(anchor);
				});
			}
		});
	};
};

export const editClient = (input, id) => {
	let url = baseUrl + "clients/" + id;
	return (dispatch) => {
		return fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				access_token: localStorage.access_token,
			},
			body: JSON.stringify(input),
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((text) => {
					throw new Error(text.message);
				});
			} else {
				return response.json().then((data) => {
					return data;
				});
			}
		});
	};
};

export const editAdmin = (input, id) => {
	let url = baseUrl + "admins/" + id;
	return (dispatch) => {
		return fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				access_token: localStorage.access_token,
			},
			body: JSON.stringify(input),
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((text) => {
					throw new Error(text.message);
				});
			} else {
				return response.json().then((data) => {
					return data;
				});
			}
		});
	};
};

export const login = (input) => {
	console.log(input);
	let url = baseUrl + "login";
	return (dispatch) => {
		return fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(input),
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((text) => {
					throw new Error(text.message);
				});
			} else {
				return response.json().then((data) => {
					localStorage.setItem("access_token", data.access_token);
					let userData;
					if (data.user) {
						userData = data.user;
					} else {
						userData = data.admin;
					}
					console.log(userData);
					localStorage.setItem("role", userData.role);
					return data;
				});
			}
		});
	};
};

export const uploadCertificate = (input, id) => {
	console.log("Enter upload cert");
	let url = baseUrl + "items/" + id + "/certificate";
	console.log(url);
	return (dispatch) => {
		return fetch(url, {
			method: "POST",
			// headers: {
			// 	Accept: "application/json",
			// 	"Content-Type": "multipart/form-data",
			// },
			headers: {
				access_token: localStorage.access_token,
			},
			body: input,
		})
			.then((response) => {
				if (!response.ok) {
					return response.json().then((text) => {
						throw new Error(text.message);
					});
				} else {
					return response.json().then((data) => {
						return data;
					});
				}
			})
			.catch((err) => console.log(err));
	};
};

const fetchActivityLogsSuccess = (payload) => {
	return {
		type: types.FETCH_ALL_ACTIVITY_LOGS,
		payload,
	};
};

const fetchActivitiesSuccess = (payload) => {
	return {
		type: types.FETCH_ALL_ACTIVITIES,
		payload,
	};
};

export const fetchActivityLogs = (input) => {
	console.log("fetch activity logs with input: ", input);
	let url;
	if (input) {
		const { dateInput, activityType, userType } = input;
		url =
			baseUrl +
			`activities/logs?dateInput=${dateInput}&activityType=${activityType}&userType=${userType}`;
	} else {
		url = baseUrl + "activities/logs?page=0";
	}
	console.log(url);
	return (dispatch) => {
		return fetch(url, {
			headers: {
				access_token: localStorage.access_token,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				console.log(data, "data");
				dispatch(fetchActivityLogsSuccess(data));
			})
			.catch((error) => console.log(error));
	};
};

export const fetchActivities = () => {
	let url;
	url = baseUrl + "activities";

	console.log(url);
	return (dispatch) => {
		return fetch(url, {
			headers: {
				access_token: localStorage.access_token,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				// console.log(data, "data");
				dispatch(fetchActivitiesSuccess(data));
			})
			.catch((error) => console.log(error));
	};
};
