import Modal from "react-overlays/Modal";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	fetchBrands,
	fetchClients,
	fetchItems,
	addItem,
} from "../store/actions/actionCreator";
import Select from "react-select";
import { toast } from "react-toastify";

export default function AddModal({
	showAddModal,
	handleClose,
	types,
	clients,
}) {
	const [form, setForm] = useState();
	const [selectedCustomerId, setSelectedCustomerId] = useState(0);
	const [selectedTypeId, setSelectedTypeId] = useState(0);
	const dispatch = useDispatch();
	const renderBackdrop = (props) => <div className="backdrop" />;
	const brands = useSelector((state) => {
		return state.brands.brands;
	});

	const changeHandler = (e) => {
		const { value, name } = e.target;
		const obj = { ...form };
		obj[name] = value;
		setForm(obj);
	};

	useEffect(() => {
		dispatch(fetchClients());
		dispatch(fetchBrands());
	}, []);

	function handleAdd() {
		if (selectedTypeId) {
			form.TypeId = selectedTypeId;
		}
		if (selectedCustomerId) {
			form.UserId = selectedCustomerId;
		}
		dispatch(addItem(form))
			.then(() => handleClose())
			.then(() => dispatch(fetchItems()))
			.then(() => toast.success("Berhasil menambahkan item."))
			.catch((error) => toast.error(error.message));
	}

	function handleTypeSelect(e) {
		setSelectedTypeId(e.value);
	}

	function handleCustomerSelect(e) {
		setSelectedCustomerId(e.value);
	}

	return (
		<Modal
			className="modal"
			show={showAddModal}
			onHide={handleClose}
			renderBackdrop={renderBackdrop}
		>
			<div>
				<div className="modal-header">
					<div className="modal-title">Tambah item baru</div>
					<div>
						<span
							className="close-button"
							onClick={handleClose}
						>
							x
						</span>
					</div>
				</div>
				<div className="modal-desc">
					<form className="flex flex-col gap-2">
						<div className="flex gap-2 w-full items-center">
							<label className="w-1/3">Nomor seri:</label>
							<input
								type="text"
								className="grow border border-gray-300 py-1 px-3 rounded-sm mx-0.5 text-gray-800"
								name="serialNumber"
								onChange={changeHandler}
							></input>
						</div>
						<div className="flex gap-2 w-full items-center">
							<label className="w-1/3">Nama item:</label>
							<Select
								options={types}
								className="grow py-0.5 px-1"
								name="TypeId"
								onChange={handleTypeSelect}
								value={types.filter(function (type) {
									return type.value === selectedTypeId;
								})}
							/>
						</div>

						<div className="flex gap-2 w-full items-center">
							<label className="w-1/3">Merk:</label>
							<input
								list="brands-list"
								className="grow border border-gray-300 py-1 px-3 rounded-sm mx-0.5 text-gray-800"
								name="brandName"
								onChange={changeHandler}
							/>
							<datalist id="brands-list">
								{brands.map((brand) => {
									return <option value={brand.name}>{brand.name}</option>;
								})}
							</datalist>
						</div>
						<div className="flex gap-2 w-full items-center">
							<p className="w-1/3">Klien:</p>
							<Select
								options={clients}
								className="grow py-0.5 px-1"
								onChange={handleCustomerSelect}
								value={clients.filter(function (client) {
									return client.value === selectedCustomerId;
								})}
							/>
						</div>
					</form>
				</div>
				<div className="flex gap-4 justify-center px-4 py-4 mt-4">
					<button
						className="bg-themeBlack text-white px-8 py-1"
						onClick={handleAdd}
					>
						Simpan
					</button>
				</div>
			</div>
		</Modal>
	);
}
