import Modal from "react-overlays/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { editAdmin, fetchAdmins } from "../store/actions/actionCreator";
import { toast } from "react-toastify";

export default function EditAdminModal({
	showEditModal,
	handleClose,
	adminInfo,
}) {
	const [form, setForm] = useState({
		username: adminInfo.username,
		password: adminInfo.password,
	});
	const [showInputPassword, setShowInputPassword] = useState(false);
	const dispatch = useDispatch();
	const renderBackdrop = (props) => <div className="backdrop" />;
	const toggleInputPassword = (e) => {
		e.preventDefault();
		setShowInputPassword(true);
	};
	const changeHandler = (e) => {
		const { value, name } = e.target;
		const obj = { ...form };
		obj[name] = value;
		setForm(obj);
		console.log(form);
	};

	function handleEdit(id) {
		dispatch(editAdmin(form, id))
			.then(() => handleClose())
			.then(() => dispatch(fetchAdmins()))
			.then(() => toast.success("Berhasil edit info admin."))
			.catch((error) => toast.error(error.message));
	}
	return (
		<Modal
			className="modal"
			show={showEditModal}
			onHide={handleClose}
			renderBackdrop={renderBackdrop}
		>
			<div>
				<div className="modal-header">
					<div className="modal-title">Ubah info klien</div>
					<div>
						<span
							className="close-button cursor-pointer"
							onClick={handleClose}
						>
							x
						</span>
					</div>
				</div>
				<div className="modal-desc">
					<form className="flex flex-col gap-2">
						<div className="flex gap-2 w-full items-center">
							<label className="w-1/3">Username:</label>
							<input
								type="text"
								className="border-2 rounded-lg grow py-0.5 px-1"
								name="username"
								onChange={changeHandler}
								defaultValue={adminInfo.username}
							></input>
						</div>
						{showInputPassword ? (
							<div className="flex gap-2 w-full items-center">
								<label className="w-1/3">Password:</label>
								<input
									type="password"
									className="border-2 rounded-lg grow py-0.5 px-1"
									name="password"
									onChange={changeHandler}
									placeholder="insert new password"
								></input>
							</div>
						) : (
							<button
								className="py-0.5 mt-4 w-2/3 self-center px-1 bg-red-600 text-white active:translate-y-1 text-xs cursor-pointer"
								onClick={toggleInputPassword}
							>
								klik untuk mengganti password
							</button>
						)}
					</form>
				</div>
				<div className="flex gap-4 justify-center px-4 py-4 mt-4">
					<button
						className="bg-themeBlack text-white px-8 py-1"
						onClick={()=> handleEdit(adminInfo.id)}
					>
						Simpan
					</button>
				</div>
			</div>
		</Modal>
	);
}
