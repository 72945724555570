import Modal from "react-overlays/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { editClient, fetchClients } from "../store/actions/actionCreator";
import { toast } from "react-toastify";

export default function EditClientModal({
	showEditModal,
	handleClose,
	clientInfo,
}) {
	const [form, setForm] = useState({
		username: clientInfo.username,
		password: clientInfo.password,
		companyName: clientInfo.companyName,
	});
	const [showInputPassword, setShowInputPassword] = useState(false);
	const dispatch = useDispatch();
	const renderBackdrop = (props) => <div className="backdrop" />;
	const toggleInputPassword = (e) => {
		e.preventDefault();
		setShowInputPassword(true);
	};
	const changeHandler = (e) => {
		const { value, name } = e.target;
		const obj = { ...form };
		obj[name] = value;
		setForm(obj);
	};

	function handleEdit(id) {
		dispatch(editClient(form, id))
			.then(() => handleClose())
			.then(() => dispatch(fetchClients()))
			.then(() => toast.success("Berhasil edit info customer."))
			.catch((error) => toast.error(error.message));
	}

	return (
		<Modal
			className="modal"
			show={showEditModal}
			onHide={handleClose}
			renderBackdrop={renderBackdrop}
		>
			<div>
				<div className="modal-header">
					<div className="modal-title">Ubah info klien</div>
					<div>
						<span
							className="close-button cursor-pointer"
							onClick={handleClose}
						>
							x
						</span>
					</div>
				</div>
				<div className="modal-desc">
					<form className="flex flex-col gap-2">
						<div className="flex gap-2 w-full items-center">
							<label className="w-1/3">Username:</label>
							<input
								type="text"
								name="username"
								className="border shadow-sm rounded-sm grow py-0.5 px-1"
								defaultValue={clientInfo.username}
								onChange={changeHandler}
							/>
						</div>
						<div className="flex gap-2 w-full items-center">
							<label className="w-1/3">Nama perusahaan:</label>
							<input
								type="text"
								name="companyName"
								className="border shadow-sm rounded-sm grow py-0.5 px-1"
								defaultValue={clientInfo.companyName}
								onChange={changeHandler}
							/>
						</div>
						{showInputPassword ? (
                            <div className="flex gap-2 w-full items-center">
                                <label className="w-1/3">Password:</label>
								<input
									type="password"
									className="border shadow-sm rounded-sm grow py-0.5 px-1"
									name="password"
									onChange={changeHandler}
									placeholder="insert new password"
								></input>
							</div>
						) : (
							<button
								className="py-0.5 mt-4 w-2/3 self-center px-1 bg-red-600 text-white active:translate-y-1 text-xs cursor-pointer"
								onClick={toggleInputPassword}
							>
								klik untuk mengganti password
							</button>
						)}
					</form>
				</div>
				<div className="flex gap-4 justify-center px-4 py-4 mt-4">
					<button
						className="bg-themeBlack text-white px-8 py-1"
						onClick={() => handleEdit(clientInfo.id)}
					>
						Simpan
					</button>
				</div>
			</div>
		</Modal>
	);
}
