import Modal from "react-overlays/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { uploadCertificate, fetchItems } from "../store/actions/actionCreator";
import { toast } from "react-toastify";

export default function UploadModal({
	showUploadModal,
	handleClose,
	itemInfo,
	updateItemStatus,
	StatusId,
}) {
	const renderBackdrop = (props) => <div className="backdrop" />;
	const dispatch = useDispatch();
	const [selectedFile, setSelectedFile] = useState(null);
	const onFileUpload = (id) => {
		const formData = new FormData();
		formData.append("certificate", selectedFile);
		dispatch(uploadCertificate(formData, id))
			.then(() => dispatch(updateItemStatus({ StatusId }, itemInfo.id)))
			.then(() => dispatch(fetchItems()))
			.then(() => handleClose())
			.then(() => toast.success("Sertifikat diunggah."))
			.catch((err) => console.log(err.message));
	};

	const onFileChange = (e) => setSelectedFile(e.target.files[0]);

	return (
		<Modal
			className="modal"
			show={showUploadModal}
			onHide={handleClose}
			renderBackdrop={renderBackdrop}
		>
			<div>
				<div className="modal-header">
					<div className="modal-title">Unggah sertifikat</div>
					<div>
						<span
							className="close-button"
							onClick={handleClose}
						>
							x
						</span>
					</div>
				</div>
				<div className="modal-desc flex w-full justify-center items-center">
					<input
						type="file"
						onChange={onFileChange}
					/>
				</div>
				<div className="flex gap-4 justify-center px-4 py-4 mt-4">
					<button
						className="bg-themeBlack text-white px-8 py-1"
						onClick={() => onFileUpload(itemInfo.id)}
					>
						Simpan
					</button>
				</div>
			</div>
		</Modal>
	);
}
