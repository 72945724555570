function dateToString(date) {
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, "0");
	const day = String(today.getDate()).padStart(2, "0");

	return `${day}${month}${year}`;
}

function parseStringToDate(dateString) {
	const parts = dateString.split(/[\s,]+/);
	const monthNames = [
		"Januari",
		"Februari",
		"Maret",
		"April",
		"Mei",
		"Juni",
		"Juli",
		"Agustus",
		"September",
		"Oktober",
		"November",
		"Desember",
	];
	const monthIndex = monthNames.indexOf(parts[2]);
	if (monthIndex === -1) return null;
	const date = new Date(
		parts[3],
		monthIndex,
		parts[1],
		...parseStringToTime(parts[4])
	);
	console.log(date);
	return date;
	return isNaN(date) ? null : date;
}

function parseStringToTime(timeString) {
	const timeParts = timeString.split(".");
	const hours = parseInt(timeParts[0]);
	const minutes = parseInt(timeParts[1]);
	if (isNaN(hours) || isNaN(minutes)) {
		return [0, 0];
	} else {
		return [hours, minutes];
	}
}

module.exports = { dateToString, parseStringToDate };
