import { combineReducers } from "redux";
import itemReducer from "./itemReducer";
import clientReducer from "./clientReducer"
import brandReducer from "./brandReducer";
import adminReducer from "./adminReducer";
import typeReducer from "./typeReducer";
import statusReducer from "./statusReducer"
import activityLogReducer from "./activityLogReducer"
import activityReducer from "./activityReducer"


const rootReducer = combineReducers({
	items: itemReducer,
    clients: clientReducer,
    brands: brandReducer,
    admins: adminReducer,
    types: typeReducer,
    statuses: statusReducer,
    activityLogs: activityLogReducer,
    activities: activityReducer,
})

export default rootReducer