export const FETCH_ALL_ITEMS = "FETCH_ALL_ITEMS";
export const FETCH_ITEM_DETAIL = "FETCH_CLIENT_DETAIL";
export const FETCH_ALL_CLIENTS = "FETCH_ALL_CLIENTS";
export const FETCH_CLIENT_DETAIL = "FETCH_CLIENT_DETAIL";
export const FETCH_ALL_BRANDS = "FETCH_ALL_BRANDS";
export const FETCH_ALL_ADMINS = "FETCH_ALL_ADMINS";
export const FETCH_ALL_TYPES = "FETCH_ALL_TYPES";
export const FETCH_ALL_STATUSES = "FETCH_ALL_STATUSES";
export const FETCH_ALL_ACTIVITY_LOGS = "FETCH_ALL_ACTIVITY_LOGS";
export const FETCH_ALL_ACTIVITIES = "FETCH_ALL_ACTIVITIES";
