import Sidebar from "../components/Sidebar";
import { editClient, fetchClients } from "../store/actions/actionCreator";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import AddClientModal from "../components/AddClientModal";
import EditClientModal from "../components/EditClientModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { deleteClient } from "../store/actions/actionCreator";

export default function ClientList() {
	const [addForm, setAddForm] = useState({});
	const isShrunkDesktop = useMediaQuery({ query: "(max-width: 1263px" });
	const isFullDesktop = useMediaQuery({ query: "(min-width: 1264px" });
	const isMobile = useMediaQuery({ query: "(max-width: 770px" });
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [clientInfo, setClientInfo] = useState({});
	const handleClose = () => {
		setShowAddModal(false);
		setShowEditModal(false);
	};
	const changeHandler = (e) => {
		const { value, name } = e.target;
		const obj = { ...addForm };
		obj[name] = value;
		setAddForm(obj);
		console.log(addForm);
	};

	// function handleEdit(id) {
	// 	dispatch(editClient(addForm, id))
	// 		.then(() => setShowEditModal(false))
	// 		.then(() => dispatch(fetchClients()))
	// 		.catch((error) => console.log(error));
	// }

	function handleDelete(id) {
		dispatch(deleteClient(id))
			.then(() => dispatch(fetchClients()))
			.catch((error) => console.log(error));
	}

	const renderBackdrop = (props) => <div className="backdrop" />;
	let marginLeftComponent;
	if (isShrunkDesktop) {
		marginLeftComponent = "100px";
	} else {
		marginLeftComponent = "250px";
	}

	const dispatch = useDispatch();

	const clients = useSelector((state) => {
		return state.clients.clients;
	});

	useEffect(() => {
		dispatch(fetchClients());
	}, []);

	return (
		<div className="flex w-screen h-screen">
			{showAddModal && (
				<AddClientModal
					showAddModal={showAddModal}
					handleClose={handleClose}
				/>
			)}
			{showEditModal && (
				<EditClientModal
					showEditModal={showEditModal}
					handleClose={handleClose}
					clientInfo={clientInfo}
				/>
			)}
			<button
				className="absolute bg-themeBlue bottom-10 right-10 px-8 py-4 text-white shadow-lg"
				onClick={() => setShowAddModal(true)}
			>
				Tambah klien baru
			</button>
			<Sidebar />
			<div
				className="grow px-10 py-10 flex flex-col items-start"
				style={{ marginLeft: marginLeftComponent }}
			>
				<div className="w-full border-b-2 border-themeGreen">
					<h1 className="text-2xl font-bold tracking-wide text-left">Klien</h1>
				</div>
				<div className="w-full rounded-lg mt-12 px-4 py-2">
					<table className="w-full">
						<thead className="">
							<tr className="text-left text-sm border-b bg-themeGreen text-white py-2">
								<th className="px-2 py-1">ID</th>
								<th className="px-2 py-1">Nama perusahaan</th>
								<th className="px-2 py-1">Username</th>
								<th className="px-2 py-1 text-center">Jumlah item terdaftar</th>
								<th className="px-2 py-1"></th>
							</tr>
						</thead>
						{clients && (
							<tbody>
								{clients.map((client) => {
									return (
										<tr
											className="text-left text-sm border-b"
											key={client.id}
										>
											<td className="px-2 font-bold py-3">{client._id}</td>
											<td className="px-2 py-3">{client.companyName}</td>
											<td className="px-2 py-3">{client.username}</td>
											<td className="px-2 py-3 text-center">
												{client.Items.length}
											</td>
											{/* <td className="px-2 py-3 flex gap-4">
												<FontAwesomeIcon
													icon={faPenToSquare}
													className="px-2 py-1 rounded-lg cursor-pointer text-themeBlack"
													onClick={() => {
														setShowEditModal(true);
														setClientInfo(client);
													}}
												/>
											</td> */}
											<td className="">
												<FontAwesomeIcon
													icon={faPenToSquare}
													className="px-2 py-1 rounded-lg cursor-pointer text-themeBlack"
													onClick={() => {
														setShowEditModal(true);
														setClientInfo(client);
													}}
												/>
												{localStorage.role === "director" && (
													<FontAwesomeIcon
														icon={faTrash}
														className="px-2 py-1 rounded-lg cursor-pointer text-themeBlack"
														onClick={() => handleDelete(client.id)}
													/>
												)}
											</td>
										</tr>
									);
								})}
							</tbody>
						)}
					</table>
				</div>
			</div>
		</div>
	);
}
