import Modal from "react-overlays/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addAdmin, fetchAdmins } from "../store/actions/actionCreator";
import { toast } from "react-toastify";

export default function AddAdminModal({
	showAddModal,
	handleClose,
}) {
	const [form, setForm] = useState({});
	const dispatch = useDispatch();
	const renderBackdrop = (props) => <div className="backdrop" />;
	const changeHandler = (e) => {
		const { value, name } = e.target;
		const obj = { ...form };
		obj[name] = value;
		setForm(obj);
	};

	function handleAdd() {
		dispatch(addAdmin(form))
			.then(() => handleClose())
			.then(() => dispatch(fetchAdmins()))
			.then(() => toast.success("Berhasil menambahkan admin."))
			.catch((error) => toast.error(error.message));
	}

	return (
		<Modal
			className="modal"
			show={showAddModal}
			onHide={handleClose}
			renderBackdrop={renderBackdrop}
		>
			<div>
				<div className="modal-header">
					<div className="modal-title">Tambah admin baru</div>
					<div>
						<span
							className="close-button cursor-pointer"
							onClick={handleClose}
						>
							x
						</span>
					</div>
				</div>
				<div className="modal-desc">
					<form className="flex flex-col gap-2">
						<div className="flex gap-2 w-full items-center">
							<label className="w-1/3">Username:</label>
							<input
								type="text"
								className="border shadow-sm rounded-sm grow py-0.5 px-1"
								name="username"
								onChange={changeHandler}
							></input>
						</div>
						<div className="flex gap-2 w-full items-center">
							<label className="w-1/3">Password:</label>
							<input
								type="password"
								className="border shadow-sm rounded-sm grow py-0.5 px-1"
								name="password"
								onChange={changeHandler}
							></input>
						</div>
					</form>
				</div>
				<div className="flex gap-4 justify-center px-4 py-4 mt-4">
					<button
						className="bg-themeBlack text-white px-8 py-1 shadow-md"
						onClick={handleAdd}
					>
						Simpan
					</button>
				</div>
			</div>
		</Modal>
	);
}
