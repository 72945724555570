import Modal from "react-overlays/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { uploadCertificate, fetchItems } from "../store/actions/actionCreator";
import { toast } from "react-toastify";

export default function VisitDateModal({
	showVisitDateModal,
	handleClose,
	itemInfo,
	updateItemStatus,
	StatusId,
}) {
	const renderBackdrop = (props) => <div className="backdrop" />;
	const dispatch = useDispatch();
	const [visitDate, setVisitDate] = useState(null);

	const changeHandler = (e) => {
		const { value } = e.target;
		setVisitDate(value);
	};

	const onSave = () => {
		dispatch(updateItemStatus({ StatusId, visitDate }, itemInfo.id))
			.then(() => dispatch(fetchItems()))
			.then(() => handleClose())
			.then(() => toast.success("Status diubah."))
			.catch((err) => console.log(err.message));
	};

	return (
		<Modal
			className="modal"
			show={showVisitDateModal}
			onHide={handleClose}
			renderBackdrop={renderBackdrop}
		>
			<div>
				<div className="modal-header">
					<div className="modal-title">Masukkan tanggal kunjungan</div>
					<div>
						<span
							className="close-button"
							onClick={handleClose}
						>
							x
						</span>
					</div>
				</div>
				<div className="flex gap-2 w-full items-center mt-4 px-4 py-2">
					<label className="">Tanggal kunjungan:</label>
					<input
						type="date"
						className="grow border border-gray-300 py-1 px-1 rounded-sm mx-0.5 text-gray-800"
						name="visitDate"
						onChange={changeHandler}
					></input>
				</div>
				<div className="flex gap-4 justify-center px-4 py-4 mt-4">
					<button
						className="bg-themeBlack text-white px-8 py-1"
						onClick={onSave}
					>
						Simpan
					</button>
				</div>
			</div>
		</Modal>
	);
}
