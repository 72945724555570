import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../store/actions/actionCreator";
import { toast } from "react-toastify";

export default function LoginPage() {
	const [form, setForm] = useState();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// const [latitude, setLatitude] = useState(null);
	// const [longitude, setLongitude] = useState(null);
	// const [location, setLocation] = useState(null);
	const apiKey = "AIzaSyCli8t6hKNgPRfXqrWaSurCpoJB3Cx5KLE";

	const changeHandler = (e) => {
		const { value, name } = e.target;
		const obj = { ...form };
		obj[name] = value;
		setForm(obj);
	};

	function handleLogin(e) {
		//location
		const obj = {...form};
		// obj.location = location;
		e.preventDefault();
		dispatch(login(obj))
			.then(() => navigate("/"))
			.catch((error) => toast.error(error.message));
	}

	// useEffect(() => {
	// 	if ("geolocation" in navigator) {
	// 		navigator.geolocation.getCurrentPosition(
	// 			(position) => {
	// 				setLatitude(position.coords.latitude);
	// 				setLongitude(position.coords.longitude);
	// 				fetch(
	// 					`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${apiKey}`
	// 				)
	// 					.then((response) => response.json())
	// 					.then((data) => {
	// 						if (data.status === "OK") {
	// 							const address = data.results[0].formatted_address;
	// 							// console.log("Reverse Geocoded Address:", address);
	// 							setLocation(address);
	// 						} else {
	// 							console.error("Reverse geocoding failed:", data.error_message);
	// 						}
	// 					})
	// 					.catch((error) => {
	// 						console.error("Error fetching reverse geocoding data:", error);
	// 					});
	// 			},
	// 			(error) => {
	// 				console.error("Error getting user location:", error);
	// 			}
	// 		);
	// 	} else {
	// 		console.error("Geolocation is not supported by this browser");
	// 	}
	// }, []);

	return (
		<div className="h-screen w-screen flex justify-center items-center">
			<div className="h-1/2 w-1/3 flex flex-col rounded-lg items-center py-4 px-8 bg-gray-200">
				<img
					src={require("../assets/images/sam-logo-removedbg.png")}
					alt="sam-logo"
					className="h-20 w-30"
				/>
				<h1 className="font-bold mb-6 text-sm">Web Admin</h1>

				<form
					className="flex flex-col w-full gap-4 items-center"
					onSubmit={handleLogin}
				>
					<input
						type="text"
						placeholder="username"
						className="px-4 py-2 rounded-sm shadow-md border w-4/5"
						name="username"
						onChange={changeHandler}
					></input>
					<input
						type="password"
						placeholder="password"
						className="px-4 py-2 rounded-sm shadow-md border w-4/5"
						name="password"
						onChange={changeHandler}
					></input>
					<input
						type="submit"
						value="Login"
						className="bg-themeBlue px-4 py-2 rounded-md text-white shadow-md w-4/5 mt-4 cursor-pointer"
					></input>
				</form>
			</div>
		</div>
		// <div className="flex h-screen w-screen justify-center items-center bg-red-50">
		// 	<div className="w-1/2 h-screen bg-blue-100 login"></div>
		// 	<div className="w-1/2 h-screen bg-green-400 flex flex-col items-center justify-center">
		// 		<div className="h-2/3 w-2/3 mx-auto bg-white flex flex-col">
		// 			<img src="../assets/images/sam-logo-removedbg.png" />
		// 			<h1 className="text-center mt-20 text-2xl">Masuk</h1>
		// 		</div>
		// 	</div>
		// </div>
	);
}
