import { useDispatch } from "react-redux";
import { useState } from "react";
import { fetchItems } from "../store/actions/actionCreator";
import Select from "react-select";

export default function ItemSearchBox({ clients, types, statuses }) {
	const [form, setForm] = useState({});
	const [selectedTypeId, setSelectedTypeId] = useState(null);
	const dispatch = useDispatch();

	function handleTypeSelect(e) {
		setSelectedTypeId(e.value);
	}

	const changeHandler = (e) => {
		const { value, name } = e.target;
		const obj = { ...form };
		obj[name] = value;
		if (value === "") {
			obj[name] = undefined;
		}
		setForm(obj);
		console.log(form);
	};
	const handleSearch = (e) => {
		e.preventDefault();
		if (selectedTypeId) {
			form.TypeId = selectedTypeId;
		}
		dispatch(fetchItems(form));
	};
	return (
		<form className="flex py-4 px-4 mt-6 border-themeGreen shadow-sm justify-between items-center bg-white text-themeBlack text-sm">
			<div className="flex gap-4 items-center">
				<div className="flex flex-col gap-2">
					<p className="text-sm font-semibold">Tipe:</p>
					<Select
						options={types}
						className="w-40 px-1"
						onChange={handleTypeSelect}
						value={types.filter(function (type) {
							return type.value === selectedTypeId;
						})}
					/>
				</div>
				<div className="flex flex-col gap-2">
					<p className="text-sm font-semibold">Nomor seri:</p>
					<input
						type="text"
						className="grow border border-gray-300 px-2 py-2 rounded-sm text-gray-600"
						placeholder="Search"
						name="searchSN"
						onChange={changeHandler}
					></input>
				</div>
				<div className="flex flex-col gap-2">
					<p className="text-sm font-semibold">Status:</p>
					<select
						className="grow border border-gray-300 w-40 px-2 py-2 rounded-sm text-gray-600"
						name="StatusId"
						onChange={changeHandler}
					>
						<option value="">All</option>
						{statuses.map((status) => {
							return (
								<option
									value={status.id}
									key={status.id}
								>
									{status.name}
								</option>
							);
						})}
					</select>
				</div>
				<div className="flex flex-col gap-2 w-40">
					<p className="text-sm font-semibold">Klien:</p>

					<select
						className="grow border border-gray-300 py-2 px-2 rounded-sm text-gray-600"
						name="UserId"
						onChange={changeHandler}
					>
						<option value="">All</option>
						{clients.map((client) => {
							return (
								<option
									value={client.id}
									key={client.id}
								>
									{client.companyName}
								</option>
							);
						})}
					</select>
				</div>
				<div className="flex flex-col gap-2">
					<p className="text-sm font-semibold">Sertifikat:</p>
					<input
						type="text"
						className="grow border border-gray-300 px-2 py-2 rounded-sm text-gray-600"
						placeholder="Search"
						name="searchCert"
						onChange={changeHandler}
					></input>
				</div>
				<button
					className="bg-themeBlue text-white font-bold py-2 px-3 rounded-sm h-1/2 align-center ml-2"
					onClick={handleSearch}
				>
					Cari
				</button>
			</div>
		</form>
	);
}
