import Modal from "react-overlays/Modal";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	fetchBrands,
	fetchClients,
	fetchItems,
	editItem,
} from "../store/actions/actionCreator";
import Select from "react-select";
import { toast } from "react-toastify";

export default function EditModal({
	showEditModal,
	handleClose,
	itemInfo,
	types,
	statuses,
}) {
	const initialState = {
		TypeId: itemInfo.TypeId,
		serialNumber: itemInfo.serialNumber,
		brandName: itemInfo.Brand.name,
	};
	console.log(statuses)
	const [form, setForm] = useState(initialState);
	const [selectedTypeId, setSelectedTypeId] = useState(0);
	const dispatch = useDispatch();
	const renderBackdrop = (props) => <div className="backdrop" />;
	const brands = useSelector((state) => {
		return state.brands.brands;
	});

	const changeHandler = (e) => {
		const { value, name } = e.target;
		const obj = { ...form };
		obj[name] = value;
		setForm(obj);
	};

	function handleTypeSelect(e) {
		setSelectedTypeId(e.value);
	}

	const handleEdit = (id) => {
		if (selectedTypeId) {
			form.TypeId = selectedTypeId;
		}
		console.log(form);
		dispatch(editItem(form, id))
			.then(() => handleClose())
			.then(() => dispatch(fetchItems()))
			.then(() => toast.success("Berhasil edit info item."))
			.catch((error) => toast.error(error.message));
	};

	useEffect(() => {
		dispatch(fetchClients());
		dispatch(fetchBrands());
	}, []);

	return (
		<Modal
			className="modal"
			show={showEditModal}
			onHide={handleClose}
			renderBackdrop={renderBackdrop}
		>
			<div>
				<div className="modal-header">
					<div className="modal-title">Ubah info item</div>
					<div>
						<span
							className="close-button"
							onClick={handleClose}
						>
							x
						</span>
					</div>
				</div>
				<div className="modal-desc">
					<form className="flex flex-col gap-2">
						<div className="flex gap-2 w-full items-center">
							<label className="w-1/3">Nama item:</label>
							<Select
								options={types}
								className="grow py-0.5 px-1"
								onChange={handleTypeSelect}
								defaultValue={{
									label: itemInfo.Type.name,
									value: itemInfo.Type.id,
								}}
							/>
						</div>
						<div className="flex gap-2 w-full items-center">
							<label className="w-1/3">Nomor seri:</label>
							<input
								type="text"
								className="grow border border-gray-300 py-1 px-1 rounded-sm mx-0.5 text-gray-800"
								name="serialNumber"
								onChange={changeHandler}
								defaultValue={itemInfo.serialNumber}
							></input>
						</div>
						<div className="flex gap-2 w-full items-center">
							<label className="w-1/3">Merk:</label>
							<input
								list="brands-list"
								className="grow border border-gray-300 py-1 px-1 rounded-sm mx-0.5 text-gray-800"
								name="brandName"
								onChange={changeHandler}
								defaultValue={itemInfo.Brand.name}
							/>
							<datalist id="brands-list">
								{brands.map((brand) => {
									return <option value={brand.name}>{brand.name}</option>;
								})}
							</datalist>
						</div>
						<div className="flex gap-2 w-full items-center">
							<label className="w-1/3">Status:</label>
							<select
								className="grow border border-gray-300 py-1 px-1 rounded-sm mx-0.5 text-gray-800"
								onChange={changeHandler}
								name="StatusId"
							>
								<option
									value={itemInfo.StatusId}
									selected
								>
									{itemInfo.Status?.name}
								</option>
								{statuses.map((status) => {
									if (status.id != itemInfo.StatusId) {
										return (
											<option
												value={status.id}
												key={status.id}
											>
												{status.name}
											</option>
										);
									}
								})}
							</select>
						</div>
						<div className="flex gap-2 w-full items-center">
							<label className="w-1/3">Tanggal kunjungan:</label>
							<input
								type="date"
								className="grow border border-gray-300 py-1 px-1 rounded-sm mx-0.5 text-gray-800"
								name="visitDate"
								onChange={changeHandler}
								defaultValue={new Date(itemInfo.visitDate).toLocaleDateString(
									"id-ID"
								)}
							></input>
						</div>
					</form>
				</div>
				<div className="flex gap-4 justify-center px-4 py-4 mt-4">
					<button
						className="bg-themeBlack text-white px-8 py-1"
						onClick={() => handleEdit(itemInfo.id)}
					>
						Simpan
					</button>
				</div>
			</div>
		</Modal>
	);
}
