import * as types from "../actions/actionType";

const initialState = {
	brands: [],
};

export default function brandReducer(state = initialState, action) {
	const { type, payload } = action;
	let newState = {};
	switch (type) {
		case types.FETCH_ALL_BRANDS:
			newState = {
				...state,
				brands: payload,
			};
			return newState;
		default:
			return state;
	}
}
