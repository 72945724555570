import Sidebar from "../components/Sidebar";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import {
	fetchBrands,
	fetchClients,
	fetchItems,
	deleteItem,
	downloadCertificate,
	fetchTypes,
	fetchStatuses,
	updateItemStatus,
	downloadAllCertificate,
} from "../store/actions/actionCreator";
import { useSelector, useDispatch } from "react-redux";
import UploadModal from "../components/UploadModal";
import AddModal from "../components/AddItemModal";
import EditModal from "../components/EditItemModal";
import ItemSearchBox from "../components/ItemSearchBox";
import VisitDateModal from "../components/VisitDateModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPenToSquare,
	faCaretDown,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

export default function ItemList() {
	const [downloadStatus, setDownloadStatus] = useState({});
	const isShrunkDesktop = useMediaQuery({ query: "(max-width: 1263px" });
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showVisitDateModal, setShowVisitDateModal] = useState(false);
	const [itemInfo, setItemInfo] = useState({ Brand: { name: null } });
	const [selectedStatusId, setSelectedStatusId] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);

	//sorting states
	const [snSort, setSnSort] = useState("");
	const [nameSort, setNameSort] = useState("");
	const [clientSort, setClientSort] = useState("");
	const [brandSort, setBrandSort] = useState("");
	const [statusSort, setStatusSort] = useState("");
	const [dateSort, setDateSort] = useState("");

	const dispatch = useDispatch();
	const handleClose = () => {
		setShowAddModal(false);
		setShowEditModal(false);
		setShowUploadModal(false);
		setShowVisitDateModal(false);
	};

	const items = useSelector((state) => {
		return state.items.items;
	});

	const itemCount = useSelector((state) => {
		return state.items.count;
	});

	const itemsPerPage = 25;

	const totalPages = Math.ceil(itemCount / itemsPerPage);
	const startIndex = currentPage * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const subset = items.slice(startIndex, endIndex);

	const clients = useSelector((state) => {
		return state.clients.clients;
	});

	const clientSelect = clients.map((el) => {
		return { value: el.id, label: el.companyName };
	});

	const types = useSelector((state) => {
		return state.types.types;
	});

	const statuses = useSelector((state) => {
		return state.statuses.statuses;
	});

	useEffect(() => {
		dispatch(fetchItems());
		dispatch(fetchClients());
		dispatch(fetchBrands());
		dispatch(fetchTypes());
		dispatch(fetchStatuses());
	}, []);

	const changeHandler = (e) => {
		const { value } = e.target;
		setSelectedStatusId(value);
	};

	function handleDelete(id) {
		dispatch(deleteItem(id))
			.then(() => dispatch(fetchItems()))
			.catch((error) => console.log(error));
	}

	function handlePatch(id) {
		if (selectedStatusId !== null) {
			if (selectedStatusId == 5) {
				setShowUploadModal(true);
			} else if (selectedStatusId == 2) {
				setShowVisitDateModal(true);
			} else {
				dispatch(updateItemStatus({ StatusId: selectedStatusId }, id))
					.then(() => dispatch(fetchItems()))
					.then(() => toast.success("Status diubah."))
					.catch((error) => console.log(error));
			}
		}
	}

	function handleDownload(id) {
		setDownloadStatus((prevStatus) => ({
			...prevStatus,
			[id]: "Downloading...",
		}));

		dispatch(downloadCertificate(id))
			.catch((error) => console.log(error))
			.finally(() =>
				setDownloadStatus((prevStatus) => ({
					...prevStatus,
					[id]: null,
				}))
			);
	}

	let marginLeftComponent;
	if (isShrunkDesktop) {
		marginLeftComponent = "100px";
	} else {
		marginLeftComponent = "250px";
	}

	function sorterAscSn(a, b) {
		return a.serialNumber.localeCompare(b.serialNumber, undefined, {
			numeric: true,
		});
	}
	function sorterDescSn(a, b) {
		return b.serialNumber.localeCompare(a.serialNumber, undefined, {
			numeric: true,
		});
	}

	function sorterAscName(a, b) {
		return a.Type.name.localeCompare(b.Type.name, undefined, {
			numeric: true,
		});
	}

	function sorterDescName(a, b) {
		return b.Type.name.localeCompare(a.Type.name, undefined, {
			numeric: true,
		});
	}

	function sorterAscClient(a, b) {
		return a.User.companyName.localeCompare(b.User.companyName, undefined, {
			numeric: true,
		});
	}

	function sorterDescClient(a, b) {
		return b.User.companyName.localeCompare(a.User.companyName, undefined, {
			numeric: true,
		});
	}

	function sorterAscStatus(a, b) {
		const idA = a.Status.id;
		const idB = b.Status.id;
		return idA - idB;
	}

	function sorterDescStatus(a, b) {
		const idA = a.Status.id;
		const idB = b.Status.id;
		return idB - idA;
	}

	//sort by date
	function sorterAscDate(a, b) {
		const dateA = new Date(a.visitDate);
		const dateB = new Date(b.visitDate);
		if (dateA === null) return 1;
		if (dateB === null) return -1;
		return dateA - dateB;
	}

	function sorterDescDate(a, b) {
		const dateA = new Date(a.visitDate);
		const dateB = new Date(b.visitDate);
		if (dateA === null) return 1;
		if (dateB === null) return -1;
		return dateB - dateA;
	}

	//sort by brand
	function sorterAscBrand(a, b) {
		const dateA = a.visitDate;
		const dateB = b.visitDate;
		if (dateA === null) return 1;
		if (dateB === null) return -1;
		return a.Brand.name.localeCompare(b.Brand.name, undefined, {
			numeric: true,
		});
	}

	function sorterDescBrand(a, b) {
		return b.Brand.name.localeCompare(a.Brand.name, undefined, {
			numeric: true,
		});
	}

	if (snSort === "ASC") {
		items.sort(sorterAscSn);
	} else if (snSort === "DESC") {
		items.sort(sorterDescSn);
	}

	if (nameSort === "ASC") {
		items.sort(sorterAscName);
	} else if (nameSort === "DESC") {
		items.sort(sorterDescName);
	}

	if (clientSort === "ASC") {
		items.sort(sorterAscClient);
	} else if (clientSort === "DESC") {
		items.sort(sorterDescClient);
	}

	if (statusSort === "ASC") {
		items.sort(sorterAscStatus);
	} else if (statusSort === "DESC") {
		items.sort(sorterDescStatus);
	}

	if (brandSort === "ASC") {
		items.sort(sorterAscBrand);
	} else if (brandSort === "DESC") {
		items.sort(sorterDescBrand);
	}

	if (dateSort === "ASC") {
		items.sort(sorterAscDate);
	} else if (dateSort === "DESC") {
		items.sort(sorterDescDate);
	}

	//download all
	const certPathsList = [];
	items.map((el) => {
		if (el.Certificates.length > 0) {
			certPathsList.push(el.Certificates[0].url);
		}
	});
	let isDownloading = false;
	function handleDownloadAll() {
		if (!isDownloading) {
			isDownloading = true;
			setIsLoading(true);
			dispatch(downloadAllCertificate(certPathsList))
				.catch((error) => console.log(error))
				.finally(() => {
					setIsLoading(false);
					isDownloading = false; // Reset the flag after download is complete or fails
				});
		}
	}

	//pagination logic
	const handlePageChange = (selectedPage) => {
		setCurrentPage(selectedPage.selected);
		dispatch(fetchItems({ page: currentPage }));
	};

	return (
		<div className="flex w-screen h-screen">
			{showAddModal && (
				<AddModal
					showAddModal={setShowAddModal}
					handleClose={handleClose}
					types={types}
					clients={clientSelect}
				/>
			)}
			{showEditModal && (
				<EditModal
					showEditModal={showEditModal}
					handleClose={handleClose}
					itemInfo={itemInfo}
					types={types}
					clients={clientSelect}
					statuses={statuses}
				/>
			)}
			<UploadModal
				showUploadModal={showUploadModal}
				updateItemStatus={updateItemStatus}
				handleClose={handleClose}
				itemInfo={itemInfo}
				StatusId={selectedStatusId}
			/>
			<VisitDateModal
				showVisitDateModal={showVisitDateModal}
				updateItemStatus={updateItemStatus}
				handleClose={handleClose}
				itemInfo={itemInfo}
				StatusId={selectedStatusId}
			/>
			<button
				className="absolute bg-themeBlue bottom-10 right-10 px-2 py-2 text-white shadow-lg text-xs"
				onClick={() => setShowAddModal(true)}
			>
				Tambah item baru
			</button>
			<Sidebar />
			<div
				className="grow px-10 py-2 flex flex-col items-start text-left"
				style={{ marginLeft: marginLeftComponent }}
			>
				<div className="flex w-full items-center gap-12">
					<ItemSearchBox
						clients={clients}
						types={types}
						statuses={statuses}
					/>
					<div className="text-center mt-4 p-2">
						<h1 className="text-sm">Jumlah item:</h1>
						<h2 className="font-bold">{itemCount}</h2>
					</div>
				</div>

				{items.length > 0 ? (
					<div className="w-full">
						{isLoading == false ? (
							<h1
								className="text-xs text-blue-600 underline cursor-pointer float-right"
								onClick={() => handleDownloadAll()}
							>
								Download semua sertifikat
							</h1>
						) : (
							<h1 className="text-right text-sm mt-4 inline">
								Download sedang berlangsung...
							</h1>
						)}
						<div className="w-full rounded-lg mt-4 py-2 mx-0">
							<table className="w-full text-xs table-sortable border-b">
								<thead className="">
									<tr className="text-left text-xs border-b bg-themeGreen text-white py-2">
										<th className="px-2 py-1 th-sort-asc">
											<div className="flex items-center gap-2">
												<p>Nama Alat</p>
												<input
													type="checkbox"
													id="hacky-input-name"
													className=""
												/>
												<label
													for="hacky-input-name"
													className="rotate cursor-pointer"
												>
													<FontAwesomeIcon
														icon={faCaretDown}
														onClick={() => {
															if (nameSort === "ASC") {
																setNameSort("DESC");
																setSnSort("");
																setClientSort("");
																setStatusSort("");
																setBrandSort("");
																setDateSort("");
															} else {
																setNameSort("ASC");
																setSnSort("");
																setClientSort("");
																setStatusSort("");
																setBrandSort("");
																setDateSort("");
															}
														}}
													/>
												</label>
											</div>
										</th>
										<th className="px-2 py-1">
											<div className="flex items-center gap-2">
												<p>Merk</p>
												<input
													type="checkbox"
													id="hacky-input-brand"
													className=""
												/>
												<label
													for="hacky-input-brand"
													className="rotate cursor-pointer"
												>
													<FontAwesomeIcon
														icon={faCaretDown}
														onClick={() => {
															if (brandSort === "ASC") {
																setBrandSort("DESC");
																setSnSort("");
																setClientSort("");
																setStatusSort("");
																setNameSort("");
																setDateSort("");
															} else {
																setBrandSort("ASC");
																setSnSort("");
																setClientSort("");
																setStatusSort("");
																setNameSort("");
																setDateSort("");
															}
														}}
													/>
												</label>
											</div>
										</th>
										<th className="px-2 py-1">
											{" "}
											<div className="flex items-center gap-2">
												<p>No. seri</p>
												<input
													type="checkbox"
													id="hacky-input-sn"
													className=""
												/>
												<label
													for="hacky-input-sn"
													className="rotate cursor-pointer"
												>
													<FontAwesomeIcon
														icon={faCaretDown}
														onClick={() => {
															if (nameSort === "ASC") {
																setNameSort("DESC");
																setSnSort("");
																setClientSort("");
																setStatusSort("");
																setBrandSort("");
																setDateSort("");
															} else {
																setNameSort("ASC");
																setSnSort("");
																setClientSort("");
																setStatusSort("");
																setBrandSort("");
																setDateSort("");
															}
														}}
													/>
												</label>
											</div>
										</th>
										<th className="px-2 py-1">
											<div className="flex items-center gap-2">
												<p>Klien</p>
												<input
													type="checkbox"
													id="hacky-input-client"
													className=""
												/>
												<label
													for="hacky-input-client"
													className="rotate cursor-pointer"
												>
													<FontAwesomeIcon
														icon={faCaretDown}
														onClick={() => {
															if (clientSort === "ASC") {
																setClientSort("DESC");
																setSnSort("");
																setNameSort("");
																setStatusSort("");
																setBrandSort("");
																setDateSort("");
															} else {
																setClientSort("ASC");
																setSnSort("");
																setNameSort("");
																setStatusSort("");
																setBrandSort("");
																setDateSort("");
															}
														}}
													/>
												</label>
											</div>
										</th>
										<th className="px-2 py-1">
											{" "}
											<div className="flex items-center gap-2">
												<p>Tanggal Kunjungan</p>
												<input
													type="checkbox"
													id="hacky-input-date"
													className=""
												/>
												<label
													for="hacky-input-date"
													className="rotate cursor-pointer"
												>
													<FontAwesomeIcon
														icon={faCaretDown}
														onClick={() => {
															if (dateSort === "ASC") {
																setDateSort("DESC");
																setSnSort("");
																setNameSort("");
																setClientSort("");
																setBrandSort("");
																setStatusSort("");
															} else {
																setDateSort("ASC");
																setSnSort("");
																setNameSort("");
																setClientSort("");
																setBrandSort("");
																setStatusSort("");
															}
														}}
													/>
												</label>
											</div>
										</th>
										<th className="px-2 py-1 th-sort-desc text-center">
											{" "}
											<div className="flex items-center gap-2">
												<p>Status</p>
												<input
													type="checkbox"
													id="hacky-input-status"
													className=""
												/>
												<label
													for="hacky-input-status"
													className="rotate cursor-pointer"
												>
													<FontAwesomeIcon
														icon={faCaretDown}
														onClick={() => {
															if (statusSort === "ASC") {
																setStatusSort("DESC");
																setSnSort("");
																setNameSort("");
																setClientSort("");
																setBrandSort("");
																setDateSort("");
															} else {
																setStatusSort("ASC");
																setSnSort("");
																setNameSort("");
																setClientSort("");
																setBrandSort("");
																setDateSort("");
															}
														}}
													/>
												</label>
											</div>
										</th>

										<th className="px-2 py-1">Sertifikat</th>
										{/* {localStorage.role === "director" && ( */}
										<th className="px-2 py-1">Tindakan</th>
										{/* )} */}
									</tr>
								</thead>
								{items && (
									<tbody>
										{subset.map((item) => {
											return (
												<tr key={item.id}>
													<td className="px-2 w-1/6">{item.Type.name}</td>
													<td className="px-2 ">{item.Brand.name}</td>
													<td className="px-2 py-1">{item.serialNumber}</td>
													<td className="px-2">{item.User.companyName}</td>
													{item.visitDate != null ? (
														<td className="py-3 px-2">
															{new Date(item.visitDate).toLocaleDateString(
																"id-ID"
															)}
														</td>
													) : (
														<td className="py-3 px-2">Belum tersedia</td>
													)}
													<td className="px-2 w-1/6">
														<div className="flex gap-2">
															<select
																name="StatusId"
																className="w-40 border"
																onChange={changeHandler}
																onClick={() => setItemInfo(item)}
															>
																<option value={item.StatusId}>
																	{item.Status.name}
																</option>
																{statuses.map((el) => {
																	if (el.id !== item.StatusId) {
																		return (
																			<option
																				value={el.id}
																				key={el.id}
																			>
																				{el.name}
																			</option>
																		);
																	}
																})}
															</select>
															<button
																className="bg-black text-white px-1 py-1 cursor-pointer"
																onClick={() => handlePatch(item.id)}
															>
																Simpan
															</button>
														</div>
													</td>
													<td className="px-2 flex gap-2">
														{item.StatusId == 5 && item.Certificates[0] ? (
															downloadStatus[item.id] != null ? (
																<h1 className="px-2 py-4">
																	{downloadStatus[item.id]}
																</h1>
															) : (
																<h1
																	className="px-2 py-4 text-blue-600 underline cursor-pointer"
																	onClick={() => handleDownload(item.id)}
																>
																	{item.Certificates[0].url
																		.substring(10)
																		.replace(".pdf", "")}
																	{/* {item.Certificates[0].id} */}
																</h1>
															)
														) : (
															<h1 className="text-center px-2 py-3">
																Belum tersedia
															</h1>
														)}
													</td>

													<td className="text-center">
														<FontAwesomeIcon
															icon={faPenToSquare}
															className="px-2 py-1 rounded-lg cursor-pointer text-themeBlack"
															onClick={() => {
																setShowEditModal(true);
																setItemInfo(item);
															}}
														/>
														{localStorage.role === "director" && (
															<FontAwesomeIcon
																icon={faTrash}
																className="px-2 py-1 rounded-lg cursor-pointer text-themeBlack"
																onClick={() => handleDelete(item.id)}
															/>
														)}
													</td>
												</tr>
											);
										})}
									</tbody>
								)}
							</table>
							<ReactPaginate
								className="flex w-full justify-center gap-10 my-8 text-xs"
								previousLabel={"<<"}
								nextLabel={">>"}
								pageCount={totalPages}
								onPageChange={handlePageChange}
								forcePage={currentPage}
								pageRangeDisplayed={3} // Change the number to control how many page numbers are displayed
								marginPagesDisplayed={1}
							/>
						</div>

						{/* <h1 className="my-6 text-center">PAGINATION: {totalPages}</h1> */}
					</div>
				) : (
					<div className="w-full h-full mx-auto items-center justify-center flex">
						<h1 className="text-center py-30">
							Tidak ada item yang dapat ditampilkan
						</h1>
					</div>
				)}
			</div>
		</div>
	);
}
