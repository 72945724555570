import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
	fetchActivities,
	fetchActivityLogs,
} from "../store/actions/actionCreator";

export default function ActivityLogFilterBox() {
	const [form, setForm] = useState({});
	const [selectedDate, setSelectedDate] = useState(null);
	const dispatch = useDispatch();
	const activities = useSelector((state) => {
		return state.activities.activities;
	});

	useEffect(() => {
		dispatch(fetchActivities());
	}, []);

	const changeHandler = (e) => {
		const { value, name } = e.target;
		const obj = { ...form };
		obj[name] = value;
		if (value === "") {
			obj[name] = undefined;
		}
		setForm(obj);
		console.log(form);
	};
	const handleFilter = (e) => {
		e.preventDefault();
		console.log(form);
		dispatch(fetchActivityLogs(form));
	};
	return (
		<form className="flex py-4 px-4 mt-6 border-themeGreen shadow-sm justify-between items-center bg-white text-themeBlack text-sm w-full">
			<div className="flex gap-4 items-center space-x-6">
				<div className="flex flex-col gap-2">
					<p className="text-sm font-semibold text-left">Tanggal:</p>
					<input
						type="date"
						id="dateInput"
						name="dateInput"
						onChange={changeHandler}
						className="grow border border-gray-300 w-40 px-2 py-2 rounded-sm text-gray-600"
					/>
				</div>
				<div className="flex flex-col gap-2">
					<p className="text-sm font-semibold text-left">Tipe Aktivitas:</p>
					<select
						className="grow border border-gray-300 w-40 px-2 py-2 rounded-sm text-gray-600"
						name="activityType"
						onChange={changeHandler}
					>
						<option value="">all</option>
						{activities.map((activity) => {
							return (
								<option
									value={activity.id}
									key={activity.id}
								>
									{activity.name}
								</option>
							);
						})}
					</select>
				</div>
				<div className="flex flex-col gap-2 w-40">
					<p className="text-sm font-semibold text-left">Tipe Pengguna:</p>
					<select
						className="grow border border-gray-300 py-2 px-2 rounded-sm text-gray-600"
						name="userType"
						onChange={changeHandler}
					>
						<option value="">all</option>
						<option value="admin">admin</option>
						<option value="customer">customer</option>
					</select>
				</div>
				<button
					className="bg-themeBlue text-white font-bold py-2 px-3 rounded-sm h-1/2 align-center ml-2"
					onClick={handleFilter}
				>
					Filter
				</button>
			</div>
		</form>
	);
}
