import * as types from "../actions/actionType";

const initialState = {
	items: [],
	itemDetail: {},
};

export default function itemReducer(state = initialState, action) {
	const { type, payload } = action;
	let newState = {};
	switch (type) {
		case types.FETCH_ALL_ITEMS:
			newState = {
				...state,
				items: payload.items,
				count: payload.count
			};
			return newState;
		case types.FETCH_ITEM_DETAIL:
			newState = {
				...state,
				itemDetail: payload,
			};
			return newState;
		default:
			return state;
	}
}
