import * as types from "../actions/actionType";

const initialState = {
	admins: [],
};

export default function adminReducer(state = initialState, action) {
	const { type, payload } = action;
	let newState = {};
	switch (type) {
		case types.FETCH_ALL_ADMINS:
			newState = {
				...state,
				admins: payload,
			};
			return newState;
		default:
			return state;
	}
}
