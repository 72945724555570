import { createBrowserRouter } from "react-router-dom";
import ItemList from "../pages/ItemList";
import ClientList from "../pages/ClientList";
import AdminList from "../pages/AdminList";
import ActivityLog from "../pages/ActivityLog";
import Main from "../pages/Main";
import LoginPage from "../pages/LoginPage";
import { redirect } from "react-router-dom";

const protectRoute = () => {
	if (!localStorage.access_token) {
		throw redirect("/login");
	} else {
		return null;
	}
};
const router = createBrowserRouter([
	{
		path: "/",
		element: <Main />,
		children: [
			{
				path: "",
				element: <ItemList />,
				loader: protectRoute,
				loader: () => {
					if (!localStorage.access_token) {
						throw redirect("/login");
					} else {
						return null;
					}
				},
			},
			{
				path: "clients",
				element: <ClientList />,
				loader: () => {
					if (!localStorage.access_token) {
						throw redirect("/login");
					} else {
						if (localStorage.role === "customer") {
							throw redirect("");
						} else {
							return null;
						}
					}
				},
			},
			{
				path: "admins",
				element: <AdminList />,
				loader: () => {
					if (!localStorage.access_token) {
						throw redirect("/login");
					} else {
						if (localStorage.role !== "director") {
							throw redirect("");
						} else {
							return null;
						}
					}
				},
			},
			{
				path: "logs",
				element: <ActivityLog />,
				loader: () => {
					if (!localStorage.access_token) {
						throw redirect("/login");
					} else {
						if (localStorage.role !== "director") {
							throw redirect("");
						} else {
							return null;
						}
					}
				},
			},
		],
	},
	{
		path: "/login",
		element: <LoginPage />,
		loader: () => {
			if (localStorage.access_token) {
				throw redirect("/");
			} else {
				return null;
			}
		},
	},
]);

export default router;
