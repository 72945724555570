import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-regular-svg-icons";
import {
	faList,
	faUsers,
	faArrowRightFromBracket,
	faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Sidebar() {
	const isShrunkDesktop = useMediaQuery({ query: "(max-width: 1263px" });
	const isFullDesktop = useMediaQuery({ query: "(min-width: 1264px" });
	const isMobile = useMediaQuery({ query: "(max-width: 770px" });
	const navigate = useNavigate();
	const role = localStorage.role;

	let componentWidth;
	if (isShrunkDesktop) {
		componentWidth = "100px";
	} else {
		componentWidth = "250px";
	}
	function handleLogout() {
		localStorage.clear();
		navigate("/login");
		toast.success("Anda berhasil keluar.");
	}

	return (
		<div
			className=" h-screen px-4 pb-10 border-r-2 fixed left-0 bg-themeCream z-10 shadow-lg"
			id="sidebar"
			style={{
				width: componentWidth,
			}}
		>
			<div className="flex flex-col h-full justify-between text-sm">
				{!isShrunkDesktop && (
					<div className="px-4 py-2 flex flex-col items-center">
						<div className="flex justify-center w-full">
							<img
								src={require("../assets/images/sam-logo-removedbg.png")}
								className="w-full"
							/>
						</div>
						<p className="font-bold tracking-wide">Dashboard Admin</p>
					</div>
				)}
				<div className="flex flex-col justify-start h-1/2 gap-10 cursor-pointer">
					<Link
						className="hover:bg-themeDarkGray w-full px-4 py-2 rounded-lg flex items-center gap-4"
						to="/"
					>
						<FontAwesomeIcon icon={faList} />
						{!isShrunkDesktop && (
							<h1 className="text-lg hover:bg-themeDarkGrey">Daftar Item</h1>
						)}
					</Link>
					{role !== "customer" && (
						<Link
							className="hover:bg-themeDarkGray w-full px-4 py-2 rounded-lg flex items-center gap-4 cursor-pointer"
							to="/clients"
						>
							<FontAwesomeIcon icon={faUsers} />
							{!isShrunkDesktop && (
								<h1 className="text-lg hover:bg-themeDarkGrey">Daftar Klien</h1>
							)}
						</Link>
					)}
					{role === "director" && (
						<Link
							className="hover:bg-themeDarkGray w-full px-4 py-2 rounded-lg flex items-center gap-4 cursor-pointer"
							to="/admins"
						>
							<FontAwesomeIcon icon={faIdCard} />
							{!isShrunkDesktop && (
								<h1 className="text-lg hover:bg-themeDarkGrey">Daftar Admin</h1>
							)}
						</Link>
					)}
					{role === "director" && (
						<Link
							className="hover:bg-themeDarkGray w-full px-4 py-2 rounded-lg flex items-center gap-4 cursor-pointer"
							to="/logs"
						>
							<FontAwesomeIcon icon={faClipboard} />
							{!isShrunkDesktop && (
								<h1 className="text-lg hover:bg-themeDarkGrey">
									Log Aktivitas
								</h1>
							)}
						</Link>
					)}
				</div>
				<div
					className="hover:bg-themeDarkGray w-full px-4 py-2 rounded-lg flex items-center gap-4 cursor-pointer"
					onClick={handleLogout}
				>
					<FontAwesomeIcon icon={faArrowRightFromBracket} />
					{!isShrunkDesktop && <h1 className="text-lg">Keluar</h1>}
				</div>
			</div>
		</div>
	);
}
